body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Roboto, sans-serif;
}

.banner {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 5px;
  font-size: 18px;
  background: #4398fc;
  color: #fff;
  z-index: 1;
}

.banner a {
  color: #fff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
  display: flex;
}
.fittingRoomBtn {
  position: absolute;
  bottom: 60px;
  right: 30px;
}
.left-column {
  width: 65%;
}
.right-column {
  width: 35%;
}
.left-column-inner {
  position: relative;
  width: 600px;
  margin: auto;
}
.left-column img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
.left-column img.active {
  position: relative;
  opacity: 1;
}
.product-description {
  border-bottom: 1px solid #e1e8ee;
  margin-bottom: 20px;
}
.product-description span {
  font-size: 12px;
  color: #358ed7;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
.product-description h1 {
  font-weight: 300;
  font-size: 52px;
  color: #43484d;
  letter-spacing: -2px;
}
.product-description p {
  font-size: 16px;
  font-weight: 300;
  color: #86939e;
  line-height: 24px;
}
.product-color span,
.size-config span {
  font-size: 14px;
  font-weight: 400;
  color: #86939e;
  margin-bottom: 20px;
  display: inline-block;
}
.product-color {
  margin-bottom: 30px;
}
.color-choose div {
  display: inline-block;
}
.color-choose input[type="radio"] {
  display: none;
}
.color-choose input[type="radio"] + label span {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.color-choose input[type="radio"]#black + label span {
  background-color: #000;
}
.color-choose input[type="radio"]#navy + label span {
  background-color: #001a33;
}
.color-choose input[type="radio"]#gray + label span {
  background-color: #888;
}
.color-choose input[type="radio"]#lightgray + label span {
  background-color: #f0f0f0;
}
.color-choose input[type="radio"]:checked + label span {
  background-image: url(https://designmodo.com/demo/product-page/images/check-icn.svg);
  background-repeat: no-repeat;
  background-position: 50%;
}
.size-choose {
  margin-bottom: 20px;
}
.size-choose button {
  margin: 5px;
  width: 65px;
  border: 2px solid #e1e8ee;
  border-radius: 6px;
  padding: 13px 20px;
  font-size: 14px;
  color: #5e6977;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.5s;
}
.size-choose button:active,
.size-choose button:focus,
.size-choose button:hover {
  border: 2px solid #86939e;
  outline: none;
}
.size-config {
  border-bottom: 1px solid #e1e8ee;
  margin-bottom: 20px;
}
.size-config a {
  color: #358ed7;
  text-decoration: none;
  font-size: 12px;
  position: relative;
  margin: 10px 0;
  display: inline-block;
}
.size-config a:before {
  content: "?";
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 2px solid rgba(53, 142, 215, 0.5);
  display: inline-block;
  text-align: center;
  line-height: 16px;
  opacity: 0.5;
  margin-right: 5px;
}
.product-price {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.product-price span {
  font-size: 26px;
  font-weight: 300;
  color: #43474d;
  margin-right: 20px;
}
.cart-btn {
  display: inline-block;
  background-color: #7dc855;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  padding: 12px 30px;
  transition: all 0.5s;
}
.cart-btn:hover {
  background-color: #64af3d;
}
@media (max-width: 940px) {
  .container {
    flex-direction: column;
    margin-top: 60px;
  }
  .left-column,
  .left-column-inner,
  .right-column {
    width: 100%;
  }
  .left-column img {
    width: 100%;
    right: 0;
  }
}
